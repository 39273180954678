import type {
	ExperienceOnAbort,
	ExperienceOnFail,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import {
	StatusNameIsNotUniqueError,
	StatusHasNoIncomingTransitionError,
	WorkflowVersionConflictError,
	OldStatusMissingInWorkflowError,
	NewStatusMissingInWorkflowError,
} from '@atlassian/jira-business-workflows/src/services/workflow-v2/errors';
import { isValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { type FlagConfiguration, toFlagId } from '@atlassian/jira-flags';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from './messages';

type WorkflowError = {
	statusCode: number;
	message?: string;
};

const GENERIC_SAVING_ERROR = 'There was an error saving the workflows';
const excludedStatusCodes = new Set([401, 403, 404, 409]);

const isFilteredNetworkError = (error: WorkflowError | Error): boolean =>
	Boolean(
		('statusCode' in error &&
			error.statusCode != null &&
			excludedStatusCodes.has(error.statusCode)) ||
			error.message?.includes(GENERIC_SAVING_ERROR),
	);

export const getColumnActionFailedFlag = ({
	messageTitle,
	action,
	error,
	projectKey,
	issueTypeIdNotSubtask,
	statusCode,
}: {
	messageTitle: MessageDescriptor;
	action: string;
	error?: Error;
	projectKey: string;
	issueTypeIdNotSubtask: string | undefined;
	statusCode?: number;
}): FlagConfiguration => {
	const workflowEditorHref =
		issueTypeIdNotSubtask != null && issueTypeIdNotSubtask !== ''
			? `/jira/core/projects/${projectKey}/settings/issuetypes/${issueTypeIdNotSubtask}/workflow`
			: `/jira/core/projects/${projectKey}/settings/issuetypes`;
	const openWorkflowEditorAction = {
		content: messages.openWorkflowEditorAction,
		href: workflowEditorHref,
		target: '_blank',
	};

	if (error instanceof StatusNameIsNotUniqueError) {
		return {
			type: 'error',
			title: messageTitle,
			description: messages.statusNameIsNotUniqueDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`jwm-${action}-status-name-is-not-unique.flag`),
		};
	}

	if (error instanceof StatusHasNoIncomingTransitionError) {
		return {
			type: 'error',
			title: messages.statusHasNoIncomingTransitionTitle,
			description: messages.statusHasNoIncomingTransitionDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`jwm-${action}-status-has-no-incoming-transition.flag`),
		};
	}

	if (error instanceof WorkflowVersionConflictError || statusCode === 409) {
		return {
			type: 'error',
			title: messageTitle,
			description: messages.workflowVersionConflictDescription,
			actions: [
				{
					content: messages.viewWorkflowEditorAction,
					href: workflowEditorHref,
					target: '_blank',
				},
			],
			id: toFlagId(`jwm-${action}-workflow-version-conflict.flag`),
		};
	}

	if (error instanceof OldStatusMissingInWorkflowError) {
		return {
			type: 'error',
			title: messageTitle,
			description: messages.oldStatusMissingInWorkflowDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`jwm-${action}-old-status-missing-in-workflow.flag`),
		};
	}

	if (error instanceof NewStatusMissingInWorkflowError) {
		return {
			type: 'error',
			title: messageTitle,
			description: messages.newStatusMissingInWorkflowDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`jwm-${action}-new-status-missing-in-workflow.flag`),
		};
	}

	if (action === 'delete-polling') {
		return {
			type: 'warning',
			title: messageTitle,
			description: messages.deletePollingTimeoutDescription,
			actions: [openWorkflowEditorAction],
			id: toFlagId(`jwm-${action}-timeout.flag`),
		};
	}

	return {
		type: 'error',
		title: messageTitle,
		description: messages.errorFlagDescription,
		actions: [
			{
				content: messages.errorFlagActionsReload,
				onClick: () => {
					window.location.reload();
				},
				target: '_blank',
			},
			{
				content: messages.errorFlagActionsCheckStatus,
				href: 'https://jira-work-management.status.atlassian.com/',
				target: '_blank',
			},
		],
		id: toFlagId(`jwm-${action}-column.flag`),
	};
};

export const trackColumnExperience = ({
	markExperienceFailed,
	abortExperience,
	error,
	errorMessage,
}: {
	markExperienceFailed: ExperienceOnFail;
	abortExperience: ExperienceOnAbort;
	error: Error;
	errorMessage: string;
}) => {
	if (
		error instanceof StatusNameIsNotUniqueError ||
		error instanceof StatusHasNoIncomingTransitionError ||
		error instanceof WorkflowVersionConflictError ||
		error instanceof OldStatusMissingInWorkflowError ||
		error instanceof NewStatusMissingInWorkflowError ||
		isClientFetchError(error) ||
		isValidationError(error) ||
		isFilteredNetworkError(error)
	) {
		abortExperience(errorMessage);
		return;
	}

	markExperienceFailed(errorMessage, error);
};
