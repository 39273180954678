import { useMemo } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { NTH_PROJECT_CREATE } from '../../common/constants';
import { inviteUsersSessionStorage } from '../../common/util';

export const useShouldShowInviteUserModalBasedOnSessionStorage = (projectKey?: string) => {
	const { data: projectContext } = useProjectContext();
	const currentProjectKey = projectKey ?? projectContext?.projectKey;

	const shouldShowInviteUserModal = useMemo(
		() => inviteUsersSessionStorage.get(NTH_PROJECT_CREATE) === currentProjectKey,
		[currentProjectKey],
	);
	return (
		shouldShowInviteUserModal &&
		// Fire exposure event here
		expVal('invite-users-on-project-create-experiment', 'shouldShowModal', false)
	);
};
