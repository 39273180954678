import { useCallback } from 'react';
import type { Transition } from '@atlassian/jira-business-board-workflow-issues/src/types.tsx';
import { useCategoryField } from '@atlassian/jira-business-entity-project/src/controllers/category-field/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags';
import { useShowFlag } from '@atlassian/jira-issue-transition-use-show-flag/src/ui/use-show-flag/index.tsx';
import { ASSIGNEE_TYPE, PRIORITY_TYPE } from '@atlassian/jira-platform-field-config';
import {
	ASSIGNEE_ID,
	PRIORITY_ID,
	CATEGORY_ID,
	STATUS_ID,
	ISSUE_KEY_ID,
} from '../../common/constants';
import type { BoardIssue, BoardIssueFields, RankInput } from '../../common/types';
import {
	useTransitionAndRankIssueService,
	TransitionValidationError,
} from '../../services/transition-and-rank-issue';
import { useUpdateIssueFields, type FieldUpdateInput } from '../../services/update-issue-field';
import { useTransformIssues } from '../../utils/issue-transformer';
import { useIssueStoreActions } from '../board-issue-store';
import { useFieldIds } from '../field-ids';
import { useRefetchIssues } from '../refetch-issues';
import { useTransitionScreenModal, TransitionModalCanceled } from '../transition-screen-modal';
import messages from './messages';

export type UpdateIssueArgs = {
	issue: BoardIssue;
	fields?: Partial<BoardIssueFields>;
	rank?: RankInput;
	transition?: Transition | null;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = (_arg1: string, _arg2: string) => {};

export const useUpdateIssue = () => {
	const fieldIds = useFieldIds();
	const { showFlag } = useFlagService();
	const { data: categoryField } = useCategoryField();
	const { updateIssueFields, setIssues } = useIssueStoreActions();
	const transformToIssues = useTransformIssues();
	const refetchIssues = useRefetchIssues();
	const updateIssueFieldsService = useUpdateIssueFields();
	const transitionAndRankIssueService = useTransitionAndRankIssueService();
	const openTransitionScreenModal = useTransitionScreenModal();

	const { showIssueTransitionSuccessFlag } = fg('show-modernised-issue-transition-success-flag')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useShowFlag()
		: { showIssueTransitionSuccessFlag: noop };

	return useCallback(
		async ({ issue, fields, rank, transition }: UpdateIssueArgs) => {
			// optimistically update the issue fields
			updateIssueFields({ issueId: issue.id, fields, rank });

			const issueKey = issue.fields[ISSUE_KEY_ID].value;

			let hasTriedFieldsUpdate = false;
			let hasTriedStatusTransition = false;
			let hasTriedRanking = false;

			try {
				// perform field updates first as they are performed through REST
				// and cannot return the full issue data
				if (fields != null) {
					const fieldsForUpdate: FieldUpdateInput[] = [];
					if (ASSIGNEE_ID in fields) {
						fieldsForUpdate.push({
							fieldId: ASSIGNEE_TYPE,
							value: fields[ASSIGNEE_ID],
						});
					}
					if (PRIORITY_ID in fields) {
						fieldsForUpdate.push({
							fieldId: PRIORITY_TYPE,
							value: fields[PRIORITY_ID],
						});
					}
					if (CATEGORY_ID in fields && categoryField?.id != null) {
						fieldsForUpdate.push({
							fieldId: categoryField.id,
							value: fields[CATEGORY_ID],
						});
					}

					if (fieldsForUpdate.length > 0) {
						hasTriedFieldsUpdate = true;
						await updateIssueFieldsService(issue.id, fieldsForUpdate);
					}
				}

				if (transition != null || rank != null) {
					hasTriedStatusTransition = transition != null;
					hasTriedRanking = rank != null;

					if (transition?.hasScreen) {
						// if the transition has a screen, the transition is done through the modal
						await openTransitionScreenModal(issue, transition.transitionId);

						// only the ranking needs to be updated after the transition
						if (rank == null) {
							refetchIssues([issue.id]);
						} else {
							const response = await transitionAndRankIssueService({
								fieldIds,
								issueId: issue.id,
								rank,
							});

							setIssues(transformToIssues([response]));
						}
					} else {
						const response = await transitionAndRankIssueService({
							fieldIds,
							issueId: issue.id,
							rank,
							transitionId: transition?.transitionId,
						});

						setIssues(transformToIssues([response]));
					}

					const destinationStatusName = fields?.[STATUS_ID]?.status.name;
					if (
						destinationStatusName != null &&
						fg('show-modernised-issue-transition-success-flag')
					) {
						showIssueTransitionSuccessFlag(issueKey, destinationStatusName);
					}
				}
			} catch (error) {
				// if anything failed, refetch issues
				refetchIssues([issue.id]);

				// if the user cancelled the transition modal, do not show an error flag
				if (error instanceof TransitionModalCanceled) {
					throw error;
				}

				if (error instanceof TransitionValidationError) {
					showFlag({
						type: 'warning',
						title: messages.transitionValidationErrorTitle,
						description: error.message,
					});
				} else if (hasTriedStatusTransition) {
					showFlag({
						type: 'error',
						title: messages.failedToTransitionErrorTitle,
						description: messages.failedToTransitionErrorDescription,
					});
				} else if (hasTriedFieldsUpdate) {
					showFlag({
						type: 'error',
						title: messages.failedToUpdateFieldsErrorTitle,
						description: messages.failedToUpdateFieldsErrorDescription,
					});
				} else if (hasTriedRanking) {
					showFlag({
						type: 'error',
						title: messages.failedToRankErrorTitle,
						description: messages.failedToRankErrorDescription,
					});
				}

				throw error;
			}
		},
		[
			updateIssueFields,
			categoryField?.id,
			updateIssueFieldsService,
			openTransitionScreenModal,
			refetchIssues,
			transitionAndRankIssueService,
			fieldIds,
			setIssues,
			transformToIssues,
			showIssueTransitionSuccessFlag,
			showFlag,
		],
	);
};
