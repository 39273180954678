import React, { forwardRef } from 'react';
import ShowMoreIcon from '@atlaskit/icon/core/show-more-horizontal';
import MoreIcon from '@atlaskit/icon/glyph/more';
import type { TriggerProps } from '@atlaskit/popup';
import { ThemedButton } from '@atlassian/jira-business-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import messages from './messages';

type Props = Omit<TriggerProps, 'ref'> & {
	isSelected: boolean;
	onClick: () => void;
};

const MORE_ICON = <MoreIcon label="" size="small" />;

const TriggerOld = forwardRef<HTMLDivElement, Props>(
	({ onClick, isSelected, ...otherProps }, ref) => {
		const { formatMessage } = useIntl();
		return (
			<ThemedButton
				{...otherProps}
				ref={ref}
				testId="business-more-menu.ui.more-menu.more-menu-core.trigger"
				iconBefore={MORE_ICON}
				isSelected={isSelected}
				onClick={onClick}
				appearance="subtle"
				aria-label={formatMessage(messages.moreButtonLabel)}
			>
				{formatMessage(messages.moreButton)}
			</ThemedButton>
		);
	},
);

const Trigger = forwardRef<HTMLButtonElement, Props>(
	({ onClick, isSelected, ...otherProps }, ref) => {
		const { formatMessage } = useIntl();

		return (
			<ThemedButton
				{...otherProps}
				ref={ref}
				testId="business-more-menu.ui.more-menu.more-menu-core.trigger"
				iconAfter={<ShowMoreIcon label="" spacing="spacious" color="currentColor" />}
				isSelected={isSelected}
				onClick={onClick}
				aria-label={formatMessage(messages.moreButtonLabel)}
				title={formatMessage(messages.moreButton)}
			/>
		);
	},
);

export default componentWithCondition(isVisualRefreshEnabled, Trigger, TriggerOld);
