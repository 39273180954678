import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import type { Flag } from '@atlassian/jira-flags';
import Placeholder from '@atlassian/jira-placeholder';
import type { ChangeTabType } from '@atlassian/jira-share-button/src/ui/types';
import { StyledShareToSlackLoader, StyledShareToSlack } from './styled';

type Props = {
	channel?: string;
	product: 'jira' | 'confluence';
	// eslint-disable-next-line jira/react/handler-naming
	showFlag: (arg1: Flag) => unknown;
	changeTab?: ChangeTabType;
	onClose: () => void;
	cloudId: string;
	shareContentType?: string;
};

export const ShareToSlack = (props: Props) => (
	<ErrorBoundary id="async-share-to-slack" packageName="integrations">
		<Placeholder name="share-to-slack" fallback={<StyledShareToSlackLoader />}>
			{/* @ts-expect-error - TS2322 - Type '{ channel?: string | undefined; product: "jira" | "confluence"; showFlag: (arg1: Flag) => unknown; changeTab?: any; onClose: () => void; cloudId: string; }' is not assignable to type 'Readonly<ThemedOuterStyledProps<WithOptionalTheme<FlagAPI & { product: AtlassianProduct; createSlackService?: ((product: AtlassianProduct) => SlackService) | undefined; cloudId: string; } & { ...; }, any>, any>>'. */}
			<StyledShareToSlack {...props} />
		</Placeholder>
	</ErrorBoundary>
);

export { default as SlackIcon } from './slack-icon';
